@import './vars';

/* font applied in whole body */
body {
    font-family: 'Poppins', sans-serif !important;
}

/* minimum height set for whole body */
#root {
    min-height: 100vh !important;
}

/* ---------------------------------------------------
    INPUT STYLE
----------------------------------------------------- */
input, .form-select {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.form-switch {
    padding-left: 3em !important;
}

.age-group-switch {
    padding-left: 0 !important;
   
    input {
        margin-left: 0 !important;
    }
}

.verification-input {
    font-size: 25px !important;
    font-weight: 500 !important;
    border: 1px solid $primary !important;
    width: 2.2em !important;
    height: 2.2em !important;
}

.verification-input[value=""] {
    border-color: #C4C4C4 !important;
}

.form-control:focus, .form-select:focus {
    border-color: $primary !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 1px $primary !important;
}

.search-input:focus {
    border-color: transparent !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 2px #EAEAEA !important;
}

.input-group:focus-within {
    outline: 1px solid $primary !important;
}

.input-group:focus-within *:focus {
    border-color: transparent !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0px $primary !important;
}

.receipt-search {
    width: 250px !important;
}

/* ---------------------------------------------------
    FONT SIZE STYLE
----------------------------------------------------- */
.fs-8 {
    font-size: 8px !important;
}

.fs-9 {
    font-size: 9px !important;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-25 {
    font-size: 25px !important;
}

.fs-28 {
    font-size: 28px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-44 {
    font-size: 44px !important;
}

.fs-45 {
    font-size: 45px !important;
}

/* ---------------------------------------------------
    FONT WEIGHT STYLE
----------------------------------------------------- */
.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

/* ---------------------------------------------------
    DISPLAY STYLE
----------------------------------------------------- */
.d-contents {
    display: contents !important;
}

/* ---------------------------------------------------
    TEXT STYLE
----------------------------------------------------- */
.text-blue {
    color: #1D2939 !important;
}

.footer-text {
    color: #475467 !important;
    font-size: 16px !important;
}

.text-yellow {
    color: #B54708 !important;
}

.white-break-spaces {
    white-space: break-spaces !important;
}

.word-break-all {
    word-break: break-all !important;
}

/* ---------------------------------------------------
    BUTTON STYLE
----------------------------------------------------- */
.btn-outline-primary:hover {
    color: white !important;
}

/* ---------------------------------------------------
    Z-INDEX STYLE
----------------------------------------------------- */
.z-2 {
    z-index: 2 !important;
}

/* ---------------------------------------------------
    WORD SPACING STYLE
----------------------------------------------------- */
.word-spacing-2 {
    word-spacing: 2px !important;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
    min-width: 270px !important;
    max-width: 270px !important;
    background: #101828 !important;
    margin-left: -270px !important;
    transition: all 0.5s !important;

    ul p {
        color: #fff !important;
        padding: 10px !important;
    }

    .nav-item {
        color: #adb5bd !important;
        font-weight: 300 !important;
    }

    .nav-item.open {
        color: #e9ecef !important;
        background: #313b4c !important;
    }

    .nav-item.active {
        color: #e9ecef !important;
        background: #344054 !important;
    }

    .nav-item:hover {
        background: #313b4c !important;
        color: #fff !important;
        transition: .2s !important;
    }
}

.sidebar.is-open {
    margin-left: 0 !important;
    transition: .5s !important;
}

li a.dropdown-toggle::after {
    display: inline-flex !important;
    position: relative !important;
    left: 60% !important;
    top: 10% !important;
}

/* ---------------------------------------------------
    TOURNAMENT STYLE
----------------------------------------------------- */
.sport-type > input,
.tournament-category > input {
    display: none !important;
}

.event-type > input {
    display: none !important;
}

.overview-rich-text {
    img {
        max-width: 100% !important;
        cursor: default !important;
    }
}

.registration-full-text {
    min-width: 321px !important;
    background: #F79009 !important;
}

/* ---------------------------------------------------
    TOURNAMENT CARD STYLE
----------------------------------------------------- */
.tournament-card-location {
    display: -webkit-box !important;
    overflow : hidden !important;
    text-overflow: ellipsis !important;
    line-clamp: 2 !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important; 
}

/* ---------------------------------------------------
    TIMEPICKER STYLE
----------------------------------------------------- */
.react-time-picker__clear-button {
    display: none !important;
}

.react-time-picker__clock-button__icon{
    stroke: #98A2B3 !important
}

.react-time-picker__inputGroup__input {
    margin-left: 0px !important;
    text-align: start !important;
    padding-right: 0rem !important;
    padding-left: 0rem !important;
    background: transparent !important;
}

.react-time-picker__inputGroup__leadingZero {
    font-size: 14px !important;
}

.react-time-picker__inputGroup__input:focus {
    outline: none !important;
}

.react-time-picker__wrapper {
    border: none !important;
}

.react-clock {
    top: -8% !important;
}

.react-clock__second-hand__body:after {
    content: "" !important;
    display: block !important;
    width: 12px !important;
    height: 12px !important;
    position: absolute !important;
    top: 0 !important;
    left: 50% !important;
    background-color: red !important;
    border-radius: 50% !important;
    transform: translate(-50%) !important;
}

/* ---------------------------------------------------
    DATEPICKER STYLE
----------------------------------------------------- */
.react-datepicker__input-container > div {
    margin-bottom: 0 !important;
}

/* ---------------------------------------------------
    PROFILE POPOVER STYLE
----------------------------------------------------- */
.profile-popover {
    max-width: 250px !important;
    width: 250px !important;
}

/* ---------------------------------------------------
    SWEETALERT STYLE
----------------------------------------------------- */
.swal2-confirm {
    display: none !important;
}

.swal2-html-container {
    margin: 0 !important;
    padding: 0 !important;
}

.swal2-popup {
    padding: 0 !important;
}

.swal2-actions {
    display: none !important;
}

.event-delete-swal-container {
    .swal2-icon {
        width: 3em !important;
        height: 3em !important;
    }

    .swal2-actions {
        display: flex !important;
        margin: 1.25em !important;
    }

    .swal2-confirm {
        display: inline-block !important;
    }
}

.swal2-close:focus {
    box-shadow: none !important;
}

/* ---------------------------------------------------
    BACKGROUND COLOR STYLE
----------------------------------------------------- */
.bg-opacity-10 {
    --bs-bg-opacity: 0.1 !important;
}

.bg-opacity-20 {
    --bs-bg-opacity: 0.2 !important;
}

.bg-gray {
    background-color: #F9FAFB !important;
}

.bg-event {
    background-color: #FFF8F5 !important;
}

.bg-event-full {
    background-color: #F04438 !important;
}

.bg-receipt {
    background-color: #F0F1F2 !important;
}


/* ---------------------------------------------------
    TAB STYLE
----------------------------------------------------- */
[role=presentation] {
    button.active {
        border: 0 !important;
        border-radius: var(--bs-border-radius) !important;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }
}

/* ---------------------------------------------------
    MARGIN STYLE
----------------------------------------------------- */
.mt-vh-100 {
    margin-top: 100vh !important;
}

.mt-n4 {
    margin-top: -2.5rem !important;
}

.me-0-point-3 {
    margin-right: 0.05rem !important;
}

.me-0-point-4 {
    margin-right: 0.1rem !important;
}

.me-0-point-45 {
    margin-right: 0.12rem !important;
}

.me-0-point-5 {
    margin-right: 0.15rem !important;
}

.me-2-point-5 {
    margin-right: 0.7rem !important;
}


/* ---------------------------------------------------
    PROFILE PHOTO STYLE
----------------------------------------------------- */
.profile-photo {
    width: 108px !important;
    height: 108px !important;
}

.profile-small-photo {
    width: 30px !important;
    height: 30px !important;
}

/* ---------------------------------------------------
    CAROUSEL STYLE
----------------------------------------------------- */
.home-carousel {
    .carousel-caption {
        left: 0% !important;
        right: 0% !important;
    }

    .carousel-image {
        height: 220px !important;
        object-fit: cover !important;
    }

    .carousel-indicators {
        display: none !important;
    }
}

/* ---------------------------------------------------
    BANK IMAGE STYLE
----------------------------------------------------- */
.bank-image {
    width: 80px !important;
    height: 80px !important;
    object-fit: contain !important;
}

/* ---------------------------------------------------
    TOURNAMENT STYLE
----------------------------------------------------- */
.tournament-banner {
    aspect-ratio: 23/9 !important;
}

.tournament-banner-small {
    width: 24px !important;
    height: 24px !important;
}

/* ---------------------------------------------------
    REACT DATA TABLE COMPONENT STYLE
----------------------------------------------------- */
.wallet-transaction {
    .rdt_TableHead {
        display: none !important;
    }
}

.gmlwXk {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

/* ---------------------------------------------------
    TABLE STYLE
----------------------------------------------------- */
.table-fixed {
    table-layout: fixed !important;
}

/* ---------------------------------------------------
    REACT SELECT STYLE
----------------------------------------------------- */
.select__control {
    background: $primary !important;
}

.select__single-value, .select__placeholder, .css-1xc3v61-indicatorContainer {
    color: white !important;
}

/* ---------------------------------------------------
    RECEIPT TABLE STYLE
----------------------------------------------------- */
.receipt-number-header {
    width: 100px !important;
}



/* ---------------------------------------------------
    WIDTH STYLE
----------------------------------------------------- */
.vw-44 {
    width: 44vw !important;
}

.vw-96 {
    width: 96vw !important;
}

.w-lg-50 {
    @media screen and (min-width: 992px) {
        width: 50% !important;
    }
}

/* ---------------------------------------------------
    POSITION STYLE
----------------------------------------------------- */
.bottom-30 {
    bottom: 30px !important;
}

.bottom-85 {
    bottom: 85px !important;
}

.bottom-xs-210 {
    @media screen and (max-width: 991px) {
        bottom: 210px !important;
    }
}

/* ---------------------------------------------------
    TSHIRT TABLE STYLE
----------------------------------------------------- */
.tshirt-data-table {
    div[role=row] {
        .cSyyNR, .kxlfvf {
            display: none !important;
        }
    }
}